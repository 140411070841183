import React from "react";
import { Server } from "../../utils/Constant";
import "./Header.css";

interface ICustomeHeader {
  label: string;
  backgroundColor?: string;
  logo?: string;
}

export default function CustomHeader(props: ICustomeHeader) {
  return (
    <div className="topBar" style={{ backgroundColor: props.backgroundColor }}>
      <div className="marquee">
        <div>
          <span className="topBarText">{props.label}</span>
          <span className="topBarText">{props.label}</span>
        </div>
      </div>
      <div className="logo">
        <img
          className="imageContentHeader"
          src={`${
            !process.env.NODE_ENV || process.env.NODE_ENV === "development"
              ? Server.dev
              : Server.prod
          }/${props.logo}`}
        ></img>
      </div>
    </div>
  );
}
