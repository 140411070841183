import React, { useEffect } from "react";
import "./BulananWinner.css";

interface IBulananWinner {
  name?: string;
  order: number;
  show?: boolean;
  visible?: boolean
}

export default function BulananWinner(props: IBulananWinner) {
  function convertName(name?: string) {
    if (!name || props.visible) {
      return name
    }
    return `***${name.substring(5)}`;
  }

  return (
    <div
      className="containerBulananWinner"
      style={{ visibility: props.show === false ? "hidden" : "inherit" }}
    >
      <div className="bulananNumber">{props.order}</div>
      <div className="bulananText">{convertName(props.name)}</div>
      <div className="bulananStar">
        <img style={{ width: 26 }} src="./assets/bulanan/star.png"></img>
      </div>
    </div>
  );
}
